import { Divider, Heading, Image, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'

import HappyDoctorSvg from 'assets/happy-doctor.svg'

import { Info } from './Info'
import { NeedToMakeAChange } from './NeedToMakeAChange'
import { PlainAcknowledgeLayout } from './PlainAcknowledgeLayout'

import { ReferralDto } from '~shared/types'
import { maskUin } from '~shared/utils/human-readable'

export const Confirmed = ({ referral }: { referral: ReferralDto }) => {
  return (
    <PlainAcknowledgeLayout>
      <Image src={HappyDoctorSvg} boxSize="150px" mx="auto" />
      <Heading fontSize="24px" color="base.content.strong" textAlign="center">
        Referral confirmed!
      </Heading>
      <Text color="base.content.default" textAlign="center">
        {`We'll send the patient and GP doctor the confirmed appointment details. You can still change this appointment if needed.`}
      </Text>
      <Divider />
      <Info section="Where" title={referral.offering.name} />
      <Info
        section="When"
        title={dayjs(referral.timeslotStartAt).format('dddd, DD MMMM YYYY')}
      >
        <Text textStyle="h6" color="base.content.strong">
          {dayjs(referral.timeslotStartAt).format('h:mm A')}
        </Text>
      </Info>
      <Info section="Patient" title={referral.patient.name}>
        <Text>{maskUin(referral.patient.uin)}</Text>
      </Info>
      <Info section="Referring GP" title={referral.sender.name}>
        <Text>{referral.referringDoctor.name}</Text>
      </Info>
      <NeedToMakeAChange mt="2rem" />
    </PlainAcknowledgeLayout>
  )
}
