import { Flex } from '@chakra-ui/react'

import { AcknowledgeForm } from './AcknowledgeForm'
import { SubmitAcknowledgementButton } from './SubmitAcknowledgementButton'

import { ReferralDto } from '~shared/types'

export const AcknowledgeUnsubsidised = ({
  referral,
}: {
  referral: ReferralDto
}) => {
  return (
    <Flex p="2rem" bg="base.canvas.alt">
      <Flex
        flexDirection="column"
        maxWidth="40rem"
        width="100%"
        mx="auto"
        bg="white"
        borderRadius="md"
        shadow="sm"
        align="stretch"
        gap="2rem"
        p="2rem"
      >
        <AcknowledgeForm referral={referral} alignItems="stretch" />
        <SubmitAcknowledgementButton referral={referral} />
      </Flex>
    </Flex>
  )
}
