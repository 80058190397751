import { Divider, Heading, Image, Text } from '@chakra-ui/react'

import PaisehDoctorSvg from 'assets/paiseh-doctor.svg'

import { Info } from './Info'
import { PlainAcknowledgeLayout } from './PlainAcknowledgeLayout'

import { ReferralDto } from '~shared/types'
import { maskUin } from '~shared/utils/human-readable'

export const Withdrawn = ({ referral }: { referral: ReferralDto }) => {
  return (
    <PlainAcknowledgeLayout>
      <Image src={PaisehDoctorSvg} boxSize="150px" mx="auto" />
      <Heading fontSize="24px" color="base.content.strong" textAlign="center">
        This referral has been withdrawn by the referring GP doctor
      </Heading>
      <Text color="base.content.default" textAlign="center">
        No further action is required from you.
      </Text>
      <Divider />
      <Info section="Patient" title={referral.patient.name}>
        <Text>{maskUin(referral.patient.uin)}</Text>
      </Info>
      <Info section="Referring GP" title={referral.sender.name}>
        <Text>{referral.referringDoctor.name}</Text>
      </Info>
    </PlainAcknowledgeLayout>
  )
}
